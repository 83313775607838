@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
  --color: #000;
  --primary-color: #7839CD;
  --secondary-color: #F4EEFB;
  --hover-bg-color: #7839CD;
  --hover-color: #ffffff;
  --base-bg-color: #76acd8;
  --base-bg-modal-headcolor: #8AC0EB;
  --base-bg-gradient: linear-gradient(60deg, #8AC0EB 0%, #A876E8 100%);
  --button-bg-gradient: linear-gradient(180deg, #67349D 0%, #E062B5 100%);
  --light-grey: #aaa;
  --dark-color: #6a20aa;
  --white-color: #ffffff !important;
  --light-bg-color: #f6f7fb;
  --light-box-alert: #f2f5fa;
  --accordian-color: #f3f5f7;
  --user-icon-color: #959595;
  --dashboard-box-dark: #31314d;
  --user-inactive: #dd7c77;
  --user-active: #8cdcc1;
  --user-inactiver-hover: #f8b8b5;
  --bg-color-violet: #FAF5FF;
  --bg-color-light-blue: #eff7ff;
  --bg-color-light-violet: #CE9CF9;
  --bg-color-light-yellow: #faf7ef;
  --bg-color-light-green: #f1fff3;
  --para-text: #121213;
  --danger-alert: #F17105;
  --border-filter: #cbbaea;
  /* Animation */
  --transition: all 0.3s ease-in;
  /* font name */
  --roboto: "Roboto", sans-serif;
  --borderGap: 25px;
  --parent-profile-bg: #d2e5f7;
  --parent-child-bg: #dacefc;
  --dashboard-box-bg: #fff;
  --dashboard-box-bg-logo: #eff7ff;
  --dashbord-box-icon: #c2c2c263;
  --bg-color-wite: #F4EEFB;
  --filter-white-background: #fff;
  --custom-table-white: #F5F5FF;
  --scroll-bar-custom-track: #f1f1f1;
  --scroll-bar-custom-thumb: #ebebf3;
  --scroll-bar-custom-hover: #cfcfe2;
  --date-picker-month-color: #121213;
  --bg-color-dropdown: #fff;
  --main-scroll-bar-track: #f3e7fa;
  --main-scroll-bar-thumb: #e1d2eb;
  --main-scroll-bar-scrollbar: #F6F7FB;
  --bg-color-modal: #fff;
  --bg-color-dist-div: #f6f4fc;
  /* --base-bg-color:#fff; */
  --filter: none;
  --form-control-add-designation: #fff;
  --bg-light:#f5f6f7;
  --accordian-item-head:#31314d;
  --calender-picker:invert(0);
  --nav-tab-bck-color:#e5c1e9;
--nav-tab-font-color: #31314d;
--nav-tab-border-color:#dae7f1;
--para-text--designation: #31314d !important;
--both-ft-white:#7438c5;
--tag-bar-pr: #f2f1f4 !important;
}

.dark {
  --color: #959595;
  --dashboard-box-bg: #2c2a42;
  --bg-color-violet: #31314d;
  --bg-color-light-yellow: #31314d;
  --dashboard-box-bg-logo: #31314d;
  --bg-color-light-blue: #31314d;
  --bg-color-light-green: #31314d;
  --dashbord-box-icon: #ffffff44;
  --bg-color-wite: #2c2a42;
  --light-box-alert:#3e3e61;
  --filter-white-background: #31314d;
  --bg-color-light-violet: #7438c5;
  --custom-table-white: #31314d;
  --scroll-bar-custom-track: #3e3e61;
  --scroll-bar-custom-thumb: #444485;
  --scroll-bar-custom-hover: #4a4a6b;
  --light-grey: rgb(245, 233, 233);
  --date-picker-month-color: #10e922;
  --main-scroll-bar-track: #3e3e61;
  --main-scroll-bar-thumb: #444485;
  --main-scroll-bar-scrollbar: #3e3e61;
  --light-bg-color: #3e3e61;
  --bg-color-modal: #3e3e61;
  --bg-color-dropdown: #4a4a6b;
  --accordian-color: #4a4a6b;
  --white-color: #3b3b69 !important;
  --base-bg-modal-headcolor: #3b3b69 !important;
  --base-bg-gradient: #7343b3 !important;
  --bg-color-dist-div: #31314d;
  --filter: invert(1) brightness(1000%);
  --form-control-add-designation: #2c2a42;
  --para-text--designation: #aaa !important;
  --para-text:#aaa!important;
  --border-filter: #7343b3;
  --bg-light:#4a4a6b !important;
  --calender-picker:invert(1);
  --base-bg-color: #8AC0EB;
  --accordian-item-head:#e0cef8 ;
--nav-tab-bck-color: #7343b3;
--nav-tab-font-color: #fff;
--nav-tab-border-color:#7343b3;
--dark-color:#b589ef;
--both-ft-white:#fff;
--tag-bar-pr: #31314d !important;
}


.theme_1 {
  --bg-color-violet: #ffef09;
}

.theme_2 {
  --color: #fff;
  --primary-color: #7839CD;
  --secondary-color: #F4EEFB;
  --hover-bg-color: #7839CD;
  --hover-color: #ffffff;
  --base-bg-color: #76acd8;
  --base-bg-modal-headcolor: #8AC0EB;
  --base-bg-gradient: linear-gradient(60deg, #8AC0EB 0%, #A876E8 100%);
  --button-bg-gradient: linear-gradient(180deg, #67349D 0%, #E062B5 100%);
  --light-grey: #efeeee;
  --dark-color: #6a20aa;
  --white-color: #ffffff !important;
  --light-bg-color: #f6f7fb;
  --light-box-alert: #f2f5fa;
  --accordian-color: #f0f8ff;
  --user-icon-color: #ffffff;
  --dashboard-box-dark: #31314d;
  --user-inactive: #dd7c77;
  --user-active: #8cdcc1;
  --user-inactiver-hover: #f8b8b5;
  --bg-color-violet: #548fad;
  --bg-color-light-blue: #549dad;
  --bg-color-light-violet: #CE9CF9;
  --bg-color-light-yellow: #809572;
  --bg-color-light-green: #5c8d76;
  --para-text: #121213;
  --danger-alert: #F17105;
  --border-filter: #cbbaea;
  /* Animation */
  --transition: all 0.3s ease-in;
  /* font name */
  --roboto: "Roboto", sans-serif;
  --borderGap: 25px;
  --parent-profile-bg: #d2e5f7;
  --parent-child-bg: #dacefc;
  --dashboard-box-bg: #577B8D;
  --dashboard-box-bg-logo: #1b1b1c;
  --dashbord-box-icon: #c2c2c263;
  --bg-color-wite: #F4EEFB;
  --filter-white-background: #fff;
  --custom-table-white: #F5F5FF;
  --scroll-bar-custom-track: #f1f1f1;
  --scroll-bar-custom-thumb: #ebebf3;
  --scroll-bar-custom-hover: #cfcfe2;
  --date-picker-month-color: #121213;
  --bg-color-dropdown: #fff;
  --main-scroll-bar-track: #f3e7fa;
  --main-scroll-bar-thumb: #e1d2eb;
  --main-scroll-bar-scrollbar: #F6F7FB;
  --bg-color-modal: #fff;
  --bg-color-dist-div: #577B8D;
  /* --base-bg-color:#fff; */
  --filter: invert(1) brightness(1000%);
  --form-control-add-designation: #fff;
  --bg-light:#f8f9fa;
  --accordian-item-head:#31314d;
  --calender-picker:invert(0);
  --nav-tab-bck-color:#e5c1e9;
--nav-tab-font-color: #31314d;
--nav-tab-border-color:#e5c1e9;
--para-text--designation: #31314d !important;
}

.theme_3 {
  --bg-color-violet: #1ebff0;
}

.theme_4 {
  --bg-color-violet: #10e922;
}


*::-webkit-scrollbar-track {
  /* border: 1px solid #f3e7fa; */
  background-color: var(--main-scroll-bar-track) !important;
  /* border-radius: 5%; */
}

*::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: var(--main-scroll-bar-scrollbar) !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--main-scroll-bar-thumb) !important;
  /* border: 1px solid #d3bbe2; */
  /* border-left: 1px solid #d3bbe2 ;
  border-right: 1px solid #d3bbe2 ; */
  border-radius: 5%;
  cursor: pointer;
}

.user-table .rdt_TableBody{
  max-height: 100% !important;
  overflow: hidden !important;
}

.rdt_TableBody {
  max-height: 62.5vh !important;
  overflow: auto !important;
}

.total-bg-light {
  background-color: var(--dashbord-box-icon);
}

.text-light-lable {
  color: var(--light-grey) !important;
}

.headline-txt{
  color: var(--nav-tab-font-color);
}


/* div:where(.swal2-container) div:where(.swal2-popup) {
  background: #7438c5 !important;
  color: #fff !important;
} */


.custom-swal-popup {
  background: var(--light-bg-color)!important;
  color: var(--nav-tab-font-color) !important;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.accordion {
 --bs-accordion-border-color: var(--nav-tab-border-color) !important;
}

.nav-tabs {
  --bs-nav-tabs-border-color: var(--nav-tab-bck-color) !important;
  font-size: 13px;
}
.nav-link{
  color: var(--nav-tab-font-color)!important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: var(--nav-tab-font-color)!important;
  background-color: var(--nav-tab-bck-color)!important;
  border-color: var(--nav-tab-border-color)!important;
}

.user-view-container {
  background-color: var(--bg-color-modal) !important;
}

.bg-light-add {
  background-color: var(--bg-light) !important;
}



.rct-node-icon {
  color: var(--dark-color) !important;
}

.form-control-add-designation .form-control,
.form-control:focus {
  background-color: var(--form-control-add-designation) !important;
  color: var(--para-text--designation) !important;
  border: '1px solid #6f42c1' !important;
  border: 1px solid var(--border-filter) !important;
}

.form-control-add-designation .form-control::placeholder,
.form-control:focus::placeholder {
  color: #aaa !important;
}

.dark-theme {
  color: #fff !important;
}

.dashboard-p-text {
  font-size: 0.8rem !important;
  color: var(--color);
}

.box-dark {
  background-color: #2c2a42 !important;
  /* color: #FFF;  */
}

.light-view{
  background-color: var(--accordian-color);
}

.bg-white-filter {
  background-color: var(--filter-white-background) !important;
}

.box-white {
  /* background-color: #fff!important;  */
  background-color: var(--dashboard-box-bg);
  color: var(--para-text--designatio) !important;
}

.light-theme {
  color: #000 !important;
}

html {
  overflow: hidden;
}

.sub-head {
  color: var(--light-grey);
  font-size: .8rem;
}

.head-title {
  justify-content: space-between;
}

.modal-footer {
  border-top: none !important;
}

.custom-filter {
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  font-size: 0.8rem;
  border: 1px solid var(--primary-color) !important;
}

.custom-filter:hover {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.visible-section {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
}

.visible-section.show {
  opacity: 1;
  height: auto;
}

.btn-height {
  height: 38px;
}

.custom-btn {
  border-radius: 4px !important;
  background-color: var(--bg-color-wite) !important;
  color: var(--bg-filter-white);
}

.custom-btn:hover {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}


.custom-btn-1 {
  border-radius: 4px !important;
  background-color: var(--bg-color-wite) !important;
  color: var(--bg-filter-white);
}

.custom-btn-1:hover {
  background-color: #fff !important;
  color: #fff !important;
}

.custom-btn-1 .dropdown-toggle::after {
  content: none !important;
}
.map-view-holder{
  background-color: var(--bg-color-modal);
}
.bg-color {
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
  /* background-color:#2c2ce8!important; */
  background-color: var(--base-bg-modal-headcolor);
  background: var(--base-bg-gradient);
}

.bg-box-layer {
  background-color: var(--primary-color) !important;
  color: var(--white-color);
}

.btn-bg {
  background-color: var(--base-bg-color);
  background: var(--button-bg-gradient);
}

.btn-close {
  color: var(--white-color) !important;
}

.background-box {
  background-color: var(--accordian-color) !important;
  padding: 1%;
  padding-top: 2%;
  padding-left: 3%;
  border: 3px solid var(--white-color) !important;
}

.newSize {
  color: var(--base-bg-color) !important;
}

.status-cell {
  /* display: flex;
  align-items: center; */
  width: 50px;
  border-radius: 10px;
  /* padding-left: 25px; */
  /* padding-top: 4%;
  padding-bottom: 4%; */
  padding: 2%;
}

.status-text {
  
  font-weight: 700;
  font-size: 10px;
}
.btn-font{
  font-size: 0.7rem !important;
}
.status-circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* margin-right: 5px; */
  margin-left: 15px;
}

.base-color {
  color: var(--primary-color);
}

.setting-box {
  color: var(--primary-color);
}

.text-ur {
  font-size: 14px;
  color: var(--light-grey);
}

.user-icon {
  color: var(--user-icon-color);
  font-size: 1.6em;
  width: 20px;
  /* height: 20px; */

}

.user-icon:hover {
  /* color: #ce0404!important; */
  /* font-size: 1.4em; */
  /* width: 20px; */
  /* height: 20px; */
}

.dropdown-item:hover {
  background-color: var(--secondary-color);
}

.fz-2 {
  font-size: 12px !important;
}

.fz-4 {
  font-size: 0.8rem;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-600 {
  font-weight: 600;
}

.hove-color:hover {
  color: var(--primary-color) !important;
}

.active-hove-color {
  color: var(--user-active) !important;
}

.active-hove-color:hover {
  color: var(--user-active) !important;
}

.in-active-hove-color {
  color: var(--user-inactive) !important;
}

.in-active-hove-color:hover {
  color: var(--user-inactiver-hover) !important;
}

/* 
.box-tab {
  min-height: 40vh !important;
}  */

/* .box-tab {
  overflow: visible !important;
} */

.dropdown-item {
  color: #8ba1b7 !important;
}

.custom-dropdown .dropdown-menu {
  position: fixed !important;
  background-color: var(--bg-color-dropdown) !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-90w {
  width: 95% !important;
}

.modal-body-center {
  height: 78vh !important;
}


.modal-60w,
.modal-90w {
  max-width: none !important;
}

.accordion-button {
  background-color: var( --bg-color-light-blue) !important;
  /* background: linear-gradient(60deg, var(--base-bg-color) 0%, #cbaef1 100%);*/
}

.accordion-button b{
  color: var(--para-text--designation)!important;
}

.user-name {
  font-size: 12px;
  font-weight: 700;
  padding-right: 6%;
}

.password-container {
  position: relative;
}

.password-input-wrapper {
  display: flex;
  align-items: center;
}

.password-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.bg-color-violet {
  background-color: var(--bg-color-violet);
}

.dashboard-box-dark {
  background-color: var(--dashboard-box-dark);
  /* color: #88869e; */
}

.bg-color-yellow {
  background-color: var(--bg-color-light-yellow);
}

.bg-color-green {
  background-color: var(--bg-color-light-green);
}

.bg-color-blue {
  background-color: var(--bg-color-light-blue);
}

.bg-color-dist-div {
  background-color: var(--bg-color-dist-div);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  /* Bottom-left corner */
  border-top-right-radius: 25px;
  /* Top-right corner */
  border-bottom-right-radius: 25px;
  /* Bottom-right corner */
}

.vertical-line {
  position: relative;
  width: 1px;
  height: 40px;
  background: linear-gradient(to bottom, #dfe16d 50%, #bad6b8 50%);
}

.dash-text-ur {
  font-size: 0.65rem !important;
  color: var(--user-icon-color);
}

.dash-text-ur-tab {
  font-size: 0.8rem !important;
  color: var(--user-icon-color);
}

.ts-font {
  font-size: 10px;
}

.ts-bg-col {
  background-color: #d1ecec;
}

.ts-bg-act {
  background-color: #e8d7f7;
}

.ts-bg-cp {
  background-color: #f1d0bd;
}

.ts-bg-green {
  background-color: #cfe6e3;
}

.ts-bg-yellow {
  background-color: #ece6c8;
}

.ts-bg-finance {
  background-color: #dcf1cf;
}

.sys-bg-tag {
  background-color: var(--primary-color);
}

.bg-color-base {
  background-color: var(--bg-color-light-blue);
}

.bg-color-base-map {
  width: fit-content;
  background-color: rgba(255, 255, 255, 0.9);
}

.position-ab {
  position: absolute;
  top: 2px;
  right: 4px;
  padding-top: 10px;
  padding-right: 0px;
}

.size-box {
  font-size: 1em;
  padding-top: 15%;
  padding-right: 15%;
}

.position-rel {
  position: relative;
  font-size: 2.5em;
  color: var(--dashbord-box-icon);
}

.rotate-triangle {
  transform: rotate(43deg);
  /* font-size: 1em !important; */
  right: 0 !important;
}

.position-rel-dark {
  color: rgba(255, 255, 255, 0.2);
  position: relative;
  font-size: 2.5em;
}

.text-danger {
  padding-top: 2%;
  font-size: 12px;
}

/*role menu css*/
.check-box-wrap-1,
.check-box-wrap-2 {
  background-color: #ecf1f6;
  border-bottom: 1px solid #cdcdcd;
  padding: 1.5% 1.5% 1.5% 3% !important;
}

.contain-check {
  gap: 1em !important;
}

.label-name {
  width: 300px;
  font-size: 0.85rem;
}

/*mapview*/

.map-view-1 {
  /* background-color: #e9e6e6; */
  position: relative;
  /* overflow: hidden; */
  width: 100%;
  /* height: 450px; */
  /* padding-top: 52.25%;  */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

}

.tab-content .active {
  background-color: #f8f8f8 !important;
  border-bottom: none !important;
}

canvas {
  transform: none;
}

.font-ts-icon {
  font-size: 18px;
  color: #fff;
}

.layer-box {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
}

.lyr {
  position: absolute;
}

/*system dashboard*/
.para-text {
  font-size: 18px;
  color: var(--para-text);
  font-weight: 800;
}

.col-grey {
  color: var(--light-grey);
}

.bg-box {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--base-bg-color);
  background: var(--button-bg-gradient);
}

.icon-design {
  /* color: var(--white-color); */
  font-size: 18px;
}

.bg-color-alrt {
  background: var(--light-box-alert);
  border-radius: 8px;
}

.alert-t-d {
  font-size: 0.7rem;
  color: var(--user-icon-color);
}

.alert-t-h {
  font-size: 0.6rem;
}

.bg-alert-danger {
  width: 7px;
  height: 7px;
  background-color: var(--danger-alert);
  border-radius: 50%;
}

.bg-alert-success {
  width: 7px;
  height: 7px;
  background-color: #65f793;
  border-radius: 50%;
}

.alert-text {
  font-size: 0.8rem;
  font-weight: 800;
}

.visitor-h-text {
  font-size: 1.5rem;
  color: var(--primary-color);
}


.draggable {
  position: absolute;
  background: var(--white-color);
  border: 1px solid var(--para-text);
  padding: 10px;
  cursor: move;
  z-index: 1000;
  /* Ensure it appears above the map */
}

.card-header {
  border-bottom: none !important;
}

.rct-title {
  font-size: 0.8rem !important;
  color: var(--color);
}

.react-checkbox-tree button {
  color: var(--both-ft-white) !important;
}

.img-container-street {
  width: 5em;
}

.map-lebel {
  font-size: 0.5rem;
}

.new-hide-container {
  position: absolute;
  top: 0;
  right: 0;
}

.color-box-1 {
  width: 10px;
  height: 10px;
}
.color-box-2 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.color-box-3 {
  width: 10px;
  height: 4px;
  background-color:#000 ;

}
.color-box-4 {
  width: 10px;
  height: 10px;
}

.dropdown-menu-left {
  position: absolute;
  left: -55px;
  /* Adjust as needed */
  top: 65%;
  /* Adjust as needed */
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-menu-left>div {
  cursor: pointer;
}

.font-size-sale {
  font-size: 1.9rem;
}

.form-add-user {
  /* font-weight: 400;
  line-height: 1.5; */
  min-height: 38px !important;
  font-size: .8rem !important;
  border: 1px solid var(--border-filter) !important;
}

.form-add-img {
  font-size: .8rem !important;
}

.scroll-body-details {
  /* height:76.5vh; */
  max-height: 76.5vh;
  overflow-y: auto;
}

/*tree stracture*/

.d_f {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

/* section {
  width: 100%;
  height: 100vh;
  position: relative;
  float: left;
  z-index: 0;
} */

/* ==============  // Mapping child  ============== */
.treeMainContainer {
  margin: 0 auto;
  max-width: 100%;
  /* width: 100vw; */
  display: flex;
  justify-content: center;
}

.shortName {
  padding-bottom: 0 !important;
}

.treeContainer {
  width: 100%;
  position: relative;
  padding: 50px;
  z-index: 0;
}

._treeRoot {
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  z-index: 0;
}

._treeBranch {
  width: auto;
  height: min-content;
  position: relative;
  min-height: 20px;
  z-index: 0;
}

._treeRaw {
  position: relative;
  width: 100%;
  z-index: 0;
}

._treeRaw.active,
._treeRoot.active {
  z-index: 10;
}

._treeLeaf {
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: var(--borderGap);
  z-index: 1;
  /* margin: 0 15px; */
}

._treeData {
  min-width: 300px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px solid #333;
  font-size: 20px;
  background-color: var(--white-color);
  padding: 0 50px;
  white-space: nowrap;
  position: relative;
  text-align: center;
  margin-right: 10px;
  z-index: 0;
}

._treeData:last-child {
  margin-right: 0;
}

._NewBranch {
  position: relative;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}

._NewBranch>div {
  flex: 1;
}

/* ==============  Table Cell Data  ============== */
.t_Data {
  margin: 0 2px;
  position: relative;
  width: auto;
  /* max-width: 250px;
  min-width: 250px; */
  /* height: 50px; */
  background-color: var(--white-color);
  color: var(--green);
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  transition: var(--transition);
  cursor: pointer;
  z-index: 1;
}

.t_Data p {
  flex: 1;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0 !important;
}

.t_Data:hover,
.t_Data.active {
  background-color: var(--base-bg-color);
  /* color: var(--white-color); */
}

.t_Data:hover ._readMore,
.t_Data.active ._readMore {
  /* background-image: url("../images/moreWhite.svg"); */
}

.t_Data.active {
  background-color: var(--base-bg-color);
  z-index: 10;
}

.t_Data.active ._readMore {
  transform: rotate(0deg);
}

.t_Data.active .optnsCnt {
  display: block;
}

.t_Data.active .optnBx {
  margin-top: 0;
}

.optnsCnt {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  /* display: none; */
  overflow: hidden;
}

.optnBx {
  position: relative;
  height: 65px;
  background-color: var(--white-color);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 8px 6px;
  margin-bottom: 5px;
  margin-top: -200px;
  transition: var(--transition);
}

.optnBx::after {
  content: "";
  width: 1px;
  height: 20px;
  background-color: var(--para-text);
  position: absolute;
  left: calc(50% - 0.5px);
  top: calc(50% - 10px);
}

.optnBx a {
  flex: 1;
  position: relative;
  height: 100%;
  margin: 2px;
  line-height: 55px;
  border-radius: 5px;
  font-size: 24px;
  text-align: right;
  padding: 0 15px 0 10px;
  transition: var(--transition);
}

.optnBx a i {
  width: 30px;
  font-size: 22px;
  margin-left: 10px;
}

.optnBx a:hover {
  background-color: var(--base-bg-color);
  color: var(--white-color);
}

/* ==============  // Table Cell Data  ============== */

/* ==============  Mapping Cell/children  ============== */
.hasChild>._treeRaw::after,
.hasChildren>._treeRaw::after {
  content: "";
  width: 1px;
  height: var(--borderGap);
  position: absolute;
  top: calc(100% - var(--borderGap));
  left: calc(50% - 0.5px);
  background-color: var(--user-icon-color);
}

.hasChild>._treeRaw:last-child::after {
  display: none;
}

.hasChildren>._NewBranch {
  padding-top: var(--borderGap);
}

.hasChildren>._NewBranch>._treeRoot::before,
.hasChildren>._NewBranch>._treeRoot::after {
  display: block;
}

._NewBranch>._treeRoot::before,
._NewBranch>._treeRoot::after {
  display: none;
  content: "";
  position: absolute;
  right: 50%;
  width: 50%;
  height: var(--borderGap);
  top: calc(0% - var(--borderGap));
  border-top: 1px solid var(--para-text);
}

._NewBranch>._treeRoot::before {
  right: 0;
}

._NewBranch>._treeRoot::after {
  left: 0;
}

._NewBranch>._treeRoot:first-child::after,
._NewBranch>._treeRoot:last-child::before {
  display: none;
}

.hasChildren>._NewBranch>._treeRoot ._treeLeaf::after {
  content: "";
  width: 1px;
  position: absolute;
  height: var(--borderGap);
  background-color: var(--para-text);
  bottom: 100%;
}

/* ==============  // Mapping Cell  ============== */

.bg-imge-profile-parent {
  background-color: var(--parent-profile-bg);
}

.bg-imge-profile-child {
  background-color: var(--parent-child-bg);
}

.alert-box-size {
  height: 16.5em;
}

.grivance-box-size {
  height: 14.3em;
}

/* .subscription-box-size{
  height: 17.5em;
} */

.custom-table tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2 !important;
  /* Change this color to your desired stripe color */
}

.custom-table tbody tr:nth-of-type(even) {
  background-color: var(--white-color) !important;
  /* Change this color to your desired stripe color */
}

.font-tag-style {
  font-size: 0.8rem !important;
}

.bg-footer {
  background-color: #ece8f6;
}

/*error*/

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: var(--white-color);
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(./error.gif);
  height: 400px;
  background-position: center;
}

.h-error {
  font-size: 80px;
  font-weight: 600;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: var(--white-color) !important;
  padding: 10px 20px;
  background: var(--primary-color);
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.box-404 {
  height: 100vh !important;
}

.accordion-body {
  background-color: var(--accordian-color);
}

.password-rel {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 1px;
  bottom: 7px;
  background: var(--form-control-add-designation);
  /* width: 22px; */
  padding-right: 16px;
  padding-left: 20px;
}

.max-height-view {
  height: 418px;
  overflow-y: auto;
  cursor: pointer;
}

.max-height-view:hover{
  
}

.max-height-view::-webkit-scrollbar {
  /* display: none; */
}

.max-height-view {
  /* -ms-overflow-style: none; */
  /* IE and Edge */
  /* scrollbar-width: none; */
  /* Firefox */
}

.vh-height {
  height: 470px;
}

.responsive-iframe-view {
  width: 100%;
  height: 420px;
}

/* .responsive-iframe-view-project{
  width: 100%;
  height:625px;
} */

/* Custom CSS for carousel arrows */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  /* background-color: rgb(167, 54, 148);  */
  display: none !important;
}

.carousel-indicators li {
  background-color: grey !important;
  /* Set inactive indicators to grey */
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}

.carousel-indicators .active {
  background-color: #b61b1b !important;
  /* Set the active indicator color */
}

.carousel-indicators [data-bs-target] {
  background-color: #ccc0c0 !important;
}

.carousel-inner .active {
  color: rgb(171, 45, 212) !important;
  border-bottom: 2px solid #fffeff !important;
}

.sub-tx {
  font-size: 1.5em;
  color: var(--dark-color);
  font-weight: 800 !important;
}

.ms-md-auto .dropdown-toggle::after {
  color: #a669e7 !important;
  vertical-align: 0 !important;
}

.rdrCalendarWrapper {
  background: var(--bg-color-violet) !important;
}

.rdrDayNumber span {
  color: var(--light-grey) !important;
}

.rdrMonthAndYearPickers select {
  color: #af50ac !important;
}

.custom-datepicker {
  border: 1px solid var(--bg-color-light-violet) !important;
  font-family: Mulish !important;
  height: 38px;
  background-color: var(--dashboard-box-bg);
  border-radius: 4px !important;
  width: 100%;
}

.custom-datepicker .dropdown-menu {
  background-color: var(--bg-color-violet) !important;
}

.custom-black-datepicker {
  background-color: #363232 !important;
}

.btn-custom-datepicker {
  font-size: .8em !important;
  color: #a5a4a7 !important;
  border-radius: 0 !important;
  text-align: left;
}

/*
input:focus-visible{
  outline: none !important;
}

.react-datepicker__calendar-icon {
  padding-top: .7em!important;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 30px !important;
} */

/*timeline css*/
.timeline-container {
  width: 90%;
  margin: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.timeline-container ul.tl {
  margin: 20px 0;
  padding: 0;
  display: inline-block;
}

.timeline-container ul.tl li {
  list-style: none;
  margin: auto;
  min-height: 50px;
  border-left: 1px solid #86d6ff;
  padding: 0 0 50px 30px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.timeline-container ul.tl li.dashed {
  border-left: 1px dashed #86d6ff;
}

.timeline-container ul.tl li:last-child {
  border-left: 0;
}

.timeline-container ul.tl li .item-icon {
  position: absolute;
  left: -10px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: #258cc7;
  height: 20px;
  width: 20px;
}

.timeline-container ul.tl li:hover::before {
  border-color: #258cc7;
  transition: all 1000ms ease-in-out;
}

ul.tl li .item-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 70%;
}

/* ul.tl li .item-title {
} */
ul.tl li .item-detail {
  color: var(--color);
  font-size: 12px;
}

ul.tl li .item-timestamp {
  color: #8d8d8d;
  font-size: 12px;
  text-align: center;
  padding-left: 20px;
  /* width: 130px !important; */

  display: flex;
  flex-direction: row;
  align-items: center;
}

/*custom table*/
/* .custom-table {
  background-color: #1a6cbe !important; 
} */
.custom-table thead {
  border: 1px solid #E5ECFF !important;
  border-radius: 50px;
  font-size: 12px;
}

.custom-table thead tr th {
  background-color: #fefeff !important;
  /* Dark header background */
  color: #202020 !important;
  /* White text color for header */

}

/* .custom-table tbody tr {
  background-color: #e61d1d !important; 
}
*/
.custom-table tbody tr:nth-child(even) {
  background-color: #fff !important;
}

.custom-table tbody tr:hover {
  background-color: #e9ecef !important;
  /* Slightly darker on hover */
}

.custom-table th,
.custom-table td {
  padding: 1rem !important;
  /* Custom padding for cells */
  background-color: var(--custom-table-white) !important;
  font-size: 13px;
  color: #6c757d !important;
}

.table>:not(caption)>*>* {
  padding: .5rem .5rem;
  color: var(--nav-tab-font-color) !important;
  background-color: var(--custom-table-white) !important;
}


.no-data {
  text-align: center !important;
  color: #6c757d !important;
  /* Gray text color for no data row */
  background-color: var(--custom-table-white) !important;
  /* Light gray background */
}

.select-w-50 {
  width: 170px;
}

.award-tb {
  font-size: 0.7rem !important;
}

.head-th {
  border: 1px solid var(--dashbord-box-icon) !important;
  border-radius: 10px;
}

.scroll-section {
  overflow-y: auto;
  height: 150px;
}

.scroll-section2 {
  overflow-y: scroll;
  height: 300px;
  
}

.scroll-section1 {
  overflow-y: auto;
  height: 110px;
}

.relative-box {
  position: relative;
}

.fix-box {
  /* position:absolute; */
  background-color: #258cc7 !important;
  bottom: 0;
  width: 100%;
  display: flex;
}

.map-tab .nav-link {
  font-size: 12px !important;
}

.btn-map-height {
  height: 25px !important;
  --bs-btn-padding-y: 0rem !important;
}

.map-base-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99 !important;
}

.inside-map {
  position: relative;
  background-color: #6a20aa;
}

.img-container-street {
  width: 50px;
  border: 3px solid #fff;
}

/*map-show*/
/* .contain-map {
  padding: 20px;
  display: flex;
  align-items: space-around;
  justify-content: center;
  gap: 150px;
  flex-direction: column;
z-index: 9999;
position: relative;
} */

.contain-map {
  position: relative;
  top: 0;
  right: 0;
  z-index: 99 !important;
}

.movedown {
  position: relative;
  color: black;
  text-align: center;
}

/* .movedown img {
  width: 32px;
} */

.movedown a {
  color: black;
  padding: 12px;
  text-decoration: none;
  display: block;
  /* width: 110px; */
  background-color: #fff;
}

.dropbtn {
  color: rgb(0, 0, 0);
  font-size: 16px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* width: 80px; */
  position: absolute;
  top: 4px;
  right: 5px;
  padding: 1%;
  z-index: 9999;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.movedown-content {
  width: 100%;
  /* padding-top: 30px;*/
  margin-top: 5px;
  background-color: transparent;
  display: none;
  position: absolute;
  z-index: 1;
  padding-right: 64px;
}

.links {
  width: 100%;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
}

.links.hor {
  display: flex;
}

.links.rev {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.item-map {
  border: 1px solid rgb(235, 230, 235);
  background-color: #fff!important;
  font-size: 0.6rem !important;
  cursor: pointer!important;
  width: 15%;
 
}
.item-map:hover {
  cursor: pointer!important;
}
.movedown-content a:hover {
  /* background-color: #ebe2ec; */
  cursor: pointer!important;
}

.movedown:hover .movedown-content {
  display: block;
  transition: all 2s ease;
  

}
.set-map{
  width: 45px;
  height: 40px;
  border-radius: 20px;
}
.text-bottom-osm{
  font-size: 8px !important;
}

.flag {
  width: 20px !important;
  height: 20px !important;
}

.text-map {
  text-align: center;
}

/* .second-modal-backdrop {
 
  z-index: 1060 !important;
  --bs-backdrop-bg: #000000 !important;
  --bs-backdrop-opacity: 0.55 !important;
}

.second-modal-backdrop+.modal {
  z-index: 1070 !important;
}
.third-modal-backdrop {

  z-index: 1080 !important;
  --bs-backdrop-bg: #000000 !important;
  --bs-backdrop-opacity: 0.55 !important;
}

.third-modal-backdrop+.modal {
  z-index: 1090 !important;
} */


.fourth-modal-backdrop {

  z-index: 1180 !important;
  --bs-backdrop-bg: #000000 !important;
  --bs-backdrop-opacity: 0.55 !important;
}

.fourth-modal-backdrop+.modal {
  z-index: 1190 !important;
}


.swal2-custom-zindex {
  z-index: 1090 !important;
}

/* Add this CSS to your stylesheet */
.select-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Arial', sans-serif;
}

.select-container label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

#theme-select {
  width: 200px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 16px;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

#theme-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

#theme-select option {
  padding: 10px;
  background-color: #fff;
}

.btn-bg-layer {
  background-color: var( --bg-color-light-violet) !important;
  border-radius: 20px;
  color: var(--nav-tab-font-color) !important;
  border: none !important;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.react-checkbox-tree label {
  margin-bottom: 0;
  cursor: pointer;
  margin-left: 10px !important;
  margin-top: 1px !important;
}

.font-theme {
  font-size: 20px;
}

.custom-dropdown-toggle::after {
  display: none !important;
}

.file-input-container .form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2)) !important;
  padding: .600rem .8rem !important;
  font-size: .7rem !important;
  border-radius: var(--bs-border-radius-sm);
}

.mapoptionback {
  height: 80vh;
}

.mapoptionbackim {
  position: absolute;
  top: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  left: 0%;
  width: 100%;
  /* background-color: rgba(143, 13, 13, 0.5); */
}

.mapselectbox {
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
}

.mapselectbox .font-size-label {
  font-size: 16px !important;
  /* background-color: rgba(255,255,255,0.7);
   */
  background-color: var(--base-bg-color);
  background: var(--base-bg-gradient);
}

.mapoptionbackim {
  position: relative;
  /* Other styles for your div */
}

.mapoptionbackim::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.mapoptionbackim>* {
  position: relative;
  z-index: 2;
}

.font-active-count {
  color: #6a20aa;
}

.badgecolor {
  background-color: var(--filter-white-background);
  color: var(--color) !important;
}

.badgecolorProject {
  background-color: #ecfcff;
  color: var(--color) !important;
}

.land-class-process-table {
  min-height: 480px !important;
}

.land-class-progress-report {
  min-height: 330px !important;
}

.land-class-process {
  position: relative;
  min-height: 140px;
  max-height: 500px;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* Show horizontal scrollbar on hover */
.land-class-process:hover {
  overflow-x: auto; 
  overflow-y: auto;
}

.land-class-process::-webkit-scrollbar {
  height: 8px; 
  background-color: transparent;
}

.land-class-process:hover::-webkit-scrollbar {
  background-color: #ccc; 
}

.land-class-process::-webkit-scrollbar-thumb {
  background-color: #888;
}

.land-class-process:hover::-webkit-scrollbar-thumb {
  background-color: #555; 
}


.land-class-process::-webkit-scrollbar {
  width: 6px;
}

.land-class-process::-webkit-scrollbar-track {
  background: var(--scroll-bar-custom-track);
  border-radius: 0px;
}

.land-class-process::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-custom-thumb);
  border-radius: 10px;
}

.land-class-process::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar-custom-hover);
}



.land-class-process table {
  width: 100%;
  border-collapse: collapse;
}

.land-class-process .custom-table th,
.custom-table td {
  padding-top: 0.6rem !important;
  /* Custom padding for cells */
  padding-bottom: 0.6rem !important;
  /* Custom padding for cells */
  padding-left: 1rem !important;
  /* Custom padding for cells */
  padding-right: 1rem !important;
  /* Custom padding for cells */
  background-color: var(--custom-table-white) !important;
  font-size: 13px;
  color: #8c9595 !important;
}

.land-class-process thead,
.land-class-process tfoot {
  position: sticky;
  left: 0;
  right: 0;
  background: white;
  z-index: 1;
}

.land-class-process thead {
  top: 0;
}

.land-class-process tfoot {
  bottom: -1px;
}

.land-class-process tbody tr:first-child td {
  border-top: none;
}

.tftont {
  font-weight: 700;
  font-size: 12px;
}

.land-class-process .table>:not(caption)>*>* {
  border-bottom-width: 0px !important;
}

.time-badge {
  font-size: 12px !important;
  /* width: 130px!important; */
}

.badge-bg-danger {
  background-color: var(--user-inactive) !important;
  /* background-color: #dc3545;  */
  color: #fff;
}

.badge-bg-success {
  background-color: var(--user-active) !important;
  /* background-color: #dc3545;  */
  color: #fff;
}

.badge-bg-download {
  background-color: var(--base-bg-color) !important;
  /* background-color: #dc3545;  */
  color: #fff;
}


.color-box {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 2px;
}

.initial {
  background-color: #fbffd9;
}

.processing {
  background-color: #d4f0b9;
}

.final {
  background-color: #b4e3f0;
}

.not-started {
  background-color: #bebdbd;
}

.transfer{
  background-color: #CEB4F0;
}

.text-right {
  text-align: right !important;
}

.table>thead {
  vertical-align: middle !important;
}

/* .lnOUep {
  overflow:visible !important;
  white-space:pre-line !important;

} */




/* .lnndaO {
  white-space: pre-line !important;
} */

.rdt_TableCol div:first-child {
  white-space: pre-line !important;
  overflow: visible !important;
}

/* Add these styles to your CSS file */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 100;
  /* Adjust this value as necessary to ensure the header is on top */
  background-color: var(--dashboard-box-bg);
  ;
  /* Ensure the background is not transparent */
  padding: 1rem 0;
  /* Adjust padding/margins as needed */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */
}


.map-abs-top {
  position: absolute;
  top: 60px;
  left: 9px;
  z-index: 99 !important;
  color: #fff;
}

.map-top-box-cont {
  background-color: rgba(255, 255, 255, 0.7);
  max-height: 300px;
  width: fit-content;
  min-width: 250px;
  max-width: 350px;
  color: #000;
  padding: 3%;
  font-size: 12px;
  font-weight: 300;
  border-radius: 10px;
}


.map-top-box-cont .rct-title {
  font-size: 0.6rem !important;
}

.layer-shade {
  background-color: #fff;
  width: 22px;
  color: #000;
  padding-left: 3px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.mile-stone .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  /* color: var(--bs-nav-pills-link-active-color); */
  background-color: #7839cd !important;
}


.mile-stone .nav-item .nav-link {
  color: #007bff;
  font-weight: 500;
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  margin-bottom: 5px;
}


.mile-stone .nav-item .nav-link.active {
  color: white !important;
  background-color: #7839cd;
  border: 2px solid #7839cd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mile-stone .nav-item .nav-link:hover {
  background-color: #e9ecef;
  border-color: #7839cd;
  color: #e062b5;
}

.mile-stone .nav-item .nav-link:not(.active) {
  background-color: var(--light-bg-color);
  border: 1px solid #ebe8e8;
}

.mile-stone .nav.flex-column .nav-item+.nav-item {
  margin-top: 5px;
}


.css-1vmkajq {
  height: 100% !important;
  /* min-height: 100%!important; */
  padding: 4px !important;

}

.font-theme-filter {
  font-size: 14px;
}


.right-text {
  text-align: right !important;
}

.height-fix {
  height: 115px;
}

.default-box {
  height: 356px;
}

.bg-footer {
  background-color: var(--dashboard-box-bg) !important;
  width: 250px !important;
  padding-left: 4em !important;
  padding-right: 4em !important;
  padding-top: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.css-49v479 {
  /* background-color: #8AC0EB!important;
  background: linear-gradient(180deg, #67349D 0%, #E062B5 100%) !important; */
  background-color: var(--dashboard-box-bg-logo) !important;
  background: var(--dashboard-box-bg-logo) !important;
  border-radius: 0 !important;
}


.sfooter {
  background-color: var(--dashboard-box-bg-logo) !important;
  position: fixed;
  bottom: 0;
  width: 80px;
  display: flex;
  justify-content: center;
  padding-top: 29px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 30px;
}

.check-label {
  color: #aaa;
  font-size: 0.85rem;
}

.full-height-print {
  min-height: 100% !important;
  max-height: 100% !important;

}


.hd-imag-org {
  /* filter: brightness(0) saturate(100%) hue-rotate(200deg); */
  filter: var(--filter);
  /* filter: invert(1) sepia(1) saturate(5) hue-rotate(200deg); */
}
::-webkit-calendar-picker-indicator {
  filter: var(--calender-picker);
}

@media print {
  .no-print {
    display: none !important;
  }
}
@media print {
  .print-footer {
    display: none !important; /* This will be overridden later */
  }

}
.accordion-item {
  color: var(--accordian-item-head)!important;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23800080'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* Style for expanded (upward) arrow */
.accordion-button:not(.collapsed)::after {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%ffff0000'%3e%3cpath fill-rule='evenodd' d='M1.646 11.354a.5.5 0 0 1 .708 0l5.646-5.647 5.646 5.647a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1-.708 0l-6 6a.5.5 0 1 1-.708-.708z'/%3e%3c/svg%3e");
}

.modal-footer {
  background-color: var(--base-bg-gradient) !important;
}




.fixed-circle {
  position: fixed; 
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.rec{
  width: 15px;
  height: 15px;
}
.tag-bar{
  background-color: var(--tag-bar-pr) !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.back-proj-set{
  background-color: var(--custom-table-white) !important;
}
@media print {


  /* .land-class-process-table {
    display: block;
    overflow: visible;
    page-break-inside: auto;
  } */

   /* table {
    width: 100%;
    page-break-inside: auto;
  }  */

   /* thead {
    display: table-header-group; 
  }  */

  /* tfoot {
    display: table-footer-group; 
    position: absolute;
    bottom: 0;
    width: 100%;
    page-break-inside: avoid;
  } */

  /* tr {
    page-break-inside: avoid;
    page-break-after: auto;
  } */
/* 
 .custom-table tr {
    page-break-inside: avoid;
    page-break-after: auto;
  } */

  .table-footer {
    /* display: block; */
    page-break-inside: auto;
  }

  .page-break {
    display: none;
  }

  .page-break:last-child .table-footer {
    display: table-footer-group;
  }


  button, .non-printable {
    display: none;
  } 
}


.project-conten-box{
height: 100vh;
}

/*responsive codes*/

@media screen and (max-width: 2350px) {

  .rdt_TableBody {
    max-height: 72vh !important;
    overflow: auto !important;
  }

}


@media screen and (max-width: 1560px) {

  .dashboard-p-text {

    word-wrap: break-word;
    font-size: 0.8rem !important;
  }


  .rdt_TableBody {
    max-height: 62.5vh !important;
    overflow: auto !important;
  }

}


@media screen and (max-width: 1480px) {

  .dashboard-p-text {

    word-wrap: break-word;
    font-size: 0.6rem !important;
  }


}


@media screen and (max-width: 1366px) {
  .map-view {
    background-color: var(--light-bg-color);
    position: relative;
    width: 100%;
    min-height: 88vh;
    max-height: 88vh;
  }
  .layer-box {
    position: absolute;
    height: 80vh;
    top: 0;
    right: 0;
  }
}


@media screen and (max-width:750px){
  .map-view {
    min-height: 90.5vh;
    max-height: 90.5vh;
  }
  .sticky-header {
    position: static !important;

  }

  .navbar-toggler{
    display: none !important;
  }

  .layer-box {
    height: 100%;
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
}

.container {
  margin: 20px auto;
}

.progress-container {
  text-align: center;
  margin-bottom: 30px;
}

#progressbar {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  color: lightgrey;
}

#progressbar li {
  flex: 1;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  position: relative;
}

#progressbar li.active {
  color: #2F8D46;
}

.progress {
  height: 5px !important;
  background-color: rgb(243, 237, 237)!important;
  border-radius: 10px!important;
  overflow: hidden;
}

.progress-bar {
  background-color: #2F8D46;
  width: 0;
  height: 100%;
  transition: width 0.4s ease-in-out;
}

.step-container fieldset {
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
  display: none;
}

.step-container fieldset:first-of-type {
  display: block;
}

h2 {
  color: #2F8D46;
  margin-top: 10px;
  text-align: center;
}

.next-step,
.previous-step {
  width: 100px;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}

.next-step {
  background: #2F8D46;
}

.previous-step {
  background: #616161;
}

.next-step:hover,
.next-step:focus {
  background-color: #1e6f3e;
}

.previous-step:hover,
.previous-step:focus {
  background-color: #4d4d4d;
}

.text {
  color: #2F8D46;
  font-weight: normal;
}

.finish {
  text-align: center;
}